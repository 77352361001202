import React from "react";

import ContactForm from "../components/ContactForm";
import DefaultLayout from "../components/layouts/DefaultLayout";
import SEO from "../components/layouts/SEO";
import Hero from "../components/StaticPage/Hero";
import { StaticImage } from "gatsby-plugin-image";


const Contact: React.FC = () => {
  return (
    <DefaultLayout>
        <SEO
            title="Why ClickHouse Cloud | Ensemble"
            url="https://ensembleai.io/why-clickhouse-cloud"
            desc="Why Build Your Real Time Data Products Using ClickHouse Cloud"
            ogImage="https://ensembleai.io/assets/img/home.png"
        />
        <Hero
            title="Why ClickHouse Cloud"
            backgroundColor={["#40b7de", "#1976e0"]}
            description="Why Build Your Real Time Data Products Using ClickHouse Cloud"
        />

        <section className="px-6 max-w-[1188px] mx-auto space-y-8 my-16 lg:space-y-20">
            <div className="flex flex-col-reverse lg:flex-row-reverse justify-between gap-8 lg:gap-[70px] items-center">
                <StaticImage
                    className="max-w-[570px] w-full"
                    src="../images/why-clickhouse-cloud/why-clickhouse-cloud.png"
                    alt="Cloud Based Data and Analytics"
                    objectFit="contain"
                />
                <div className="space-y-[18px] lg:space-y-6 lg:max-w-[500px] w-full">
                    <h2 className="text-[28px] lg:text-[31px] font-bold gradient-blue-text ">
                        The <span className="underimage gradient-blue-text ">Highest Performing</span> Cloud Data Warehouse
                    </h2>
                    <p className="text-base">We have a particular expertise with ClickHouse Cloud, which we believe to be uniquely differentiated as the engine for real-time analytics and machine learning workloads.</p>  
                    <p className="text-base">ClickHouse is the highest performing analytical database available, making it possible to query complex real-time datasets containing billions of rows in milliseconds.</p>  
                    <p className="text-base">ClickHouse Cloud provides this as a fully managed service with a cloud native architecture, a scalable cost model and powerful integrations with the modern data stack.</p>  
                </div>
            </div>
        </section>

        <div className="mt-16 pt-6 max-w-[800px] px-3 text-center mx-auto">
            <h2 className="text-[28px] mb-8 lg:text-[31px] font-bold gradient-blue-text ">
                The <span className="underimage gradient-blue-text ">Fastest</span> Analytical Database In The Market
            </h2>
            <p>Harnessing real-time operational analytics and AI are key priorities for enterprise organisations today.  To build these solutions, we chose to build on a differentiated data platform.</p>
        </div>
        <div className="p-6 mt-4 mb-16 lg:pb-10 gap-[1rem] gap-y-[1.2rem] max-w-[1190px] flex justify-center flex-wrap mx-auto">
            <div 
                className="
                    md:max-w-[calc(50%-0.67rem)] md:min-w-[calc(50%-0.5rem)]
                    min-w-full max-w-full p-8 flex gap-4 flex-col md:flex-row items-start rounded-2xl 
                "
                style={{background: 'linear-gradient(to right, rgb(134, 118, 232), rgb(89, 86, 196))'}}
            >
                <img src="/assets/icons/fast.svg" alt="" width="64" height="" loading="lazy" />
                <div>
                    <h3 className="text-[20px] font-bold mb-4">It’s Fast</h3>
                    <p>ClickHouse is known for it’s blazing fast performance.  It is able to process billions of rows and terrabytes of data with sub-second performance, even where this data has been freshly ingested and where there are a high number of concurrent users.</p>
                </div>
            </div>

            <div 
                className="
                    md:max-w-[calc(50%-0.67rem)] md:min-w-[calc(50%-0.5rem)]
                    min-w-full max-w-full p-8 flex gap-4 flex-col md:flex-row items-start rounded-2xl 
                "
                style={{background: 'linear-gradient(to right, rgb(244, 159, 82), rgb(229, 126, 32))'}}
            >
                <img src="/assets/icons/simple.svg" alt="" width="64" height="" loading="lazy" />
                <div>
                    <h3 className="text-[20px] font-bold mb-4">It’s Simple</h3>
                    <p>ClickHouse Cloud is a fully managed cloud hosted database which requires no setup or configuration.   It is SQL based and relational making it familiar to developers and data scientists, whilst it's power and performance means that you can simplify your technology stack.  </p>
                </div>
            </div>

            <div 
                className="
                    md:max-w-[calc(50%-0.67rem)] md:min-w-[calc(50%-0.5rem)]
                    min-w-full max-w-full p-8 flex gap-4 flex-col md:flex-row items-start rounded-2xl 
                "
                style={{background: 'linear-gradient(to right, rgb(82, 186, 193), rgb(63, 147, 193))'}}
            >
                <img src="/assets/icons/scalable.svg" alt="" width="64" height="" loading="lazy" />
                <div>
                    <h3 className="text-[20px] font-bold mb-4">It’s Scalable</h3>
                    <p>ClickHouse is scable to large data volumes and high numbers of users.  This makes it suitable for big data workloads and situations where we have high degrees of concurrent access such as in user facing analytics exposed to external users. </p>
                </div>
            </div>

            <div 
                className="
                    md:max-w-[calc(50%-0.67rem)] md:min-w-[calc(50%-0.5rem)]
                    min-w-full max-w-full p-8 flex gap-4 flex-col md:flex-row items-start rounded-2xl 
                "
                style={{background: 'linear-gradient(to right, rgb(223, 106, 154), rgb(230, 77, 105))'}}
            >
                <img src="/assets/icons/open-source.svg" alt="" width="64" height="" loading="lazy" />
                <div>
                    <h3 className="text-[20px] font-bold mb-4">It’s Open Source</h3>
                    <p>Though our preference and recommendation is to use fully managed <a href="https://clickhouse.com" class="underline">ClickHouse Cloud</a> service, the fact that ClickHouse is open source is important.  This gives you an exit route if the economics of self hosting are desirable or if you need to modify the platform.</p>
                </div>
            </div>

            <div 
                className="
                    md:max-w-[calc(50%-0.67rem)] md:min-w-[calc(50%-0.5rem)]
                    min-w-full max-w-full p-8 flex gap-4 flex-col md:flex-row items-start rounded-2xl 
                "
                style={{background: 'linear-gradient(to right, rgb(212, 121, 204), rgb(194, 91, 210))'}}
            >
                <img src="/assets/icons/cost-effective.svg" alt="" width="64" height="" loading="lazy" />
                <div>
                    <h3 className="text-[20px] font-bold mb-4">It’s Cost Effective</h3>
                    <p>ClickHouse Cloud has an <a href="/blog/comparing-clickhouse-snowflake-pricing" class="underline">attractive and scalable cost model</a> which has a significantly lower TCO than competitors such as Snowflake.  Combined with other benefits such as a simplified data stack and full management, a realistic TCO of 50% can be achived.</p>
                </div>
            </div>

            <div 
                className="
                    md:max-w-[calc(50%-0.67rem)] md:min-w-[calc(50%-0.5rem)]
                    min-w-full max-w-full p-8 flex gap-4 flex-col md:flex-row items-start rounded-2xl 
                "
                style={{background: 'linear-gradient(to right, rgb(122, 216, 155), rgb(75, 185, 144))'}}
            >
                <img src="/assets/icons/secure.svg" alt="" width="64" height="" loading="lazy" />
                <div>
                    <h3 className="text-[20px] font-bold mb-4">It’s Secure & Compliant</h3>
                    <p>ClickHouse Cloud is highly secure and compliant with key standards such as PCI DSS, SOC 2, ISO 27001 and more.  It also incorporates a range of features such as encryption, role based access control and audits.  Visit the <a href="https://trust.clickhouse.com/" class="underline">ClickHouse Cloud trust center</a> to learn more.  </p>
                </div>
            </div>
        </div>

    </DefaultLayout>
  );
};

export default Contact;
